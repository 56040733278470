<template>
    <div>
        <div class="form-search-box flex end">
            <a-form layout="inline">
                <a-form-item label="日期范围">
                    <a-range-picker @change="changeOperateTime" v-model="search.data" valueFormat="YYYY-MM-DD"
                        style="width: 200px;" />
                </a-form-item>
                <a-form-item label="搜索">
                    <a-input v-model="search.keyword" style="width: 120px"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-button @click="searchAct" type="primary">查询</a-button>
                    <a-button @click="cancelAct" class="ml10">取消</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="mt20 pd20 bg-w">
            <div class="xhm-table-gray mt20">
                <a-table rowKey="invoice_id " :columns="columns" :pagination="pagination" @change="change"
                    :data-source="list" :loading="loading">
                    <!-- 发票类型 -->
                    <template slot="type" slot-scope="record,index">
                        <a-tag color="red" v-if="record.invoice_type == 1">专票</a-tag>
                        <a-tag color="green" v-if="record.invoice_type == 0">普票</a-tag>
                    </template>
                    <template slot="status" slot-scope="record,index">
                        <a-tag color="red" v-if="record.status == 0" @click="editStatus(record,1)">待提交</a-tag>
                        <a-tag color="orange" v-if="record.status == 1" @click="editStatus(record,3)">开票中</a-tag>
                        <a-tag color="purple" v-if="record.status == 2">已开票</a-tag>
                        <a-tag color="green" v-if="record.status == 3">已完成</a-tag>
                    </template>
                    <template slot="action" slot-scope="record,index">
                        <a-button type="primary" shape="circle" icon="edit" @click="editInvoice(record)" />
                    </template>
                    <!-- 关联订单 -->
                    <template slot="expandedRowRender" slot-scope="record,index">
                        <a-table rowKey="order_id" :columns="order_columns" :data-source="record.order">
                            <!-- 订单类型 -->
                            <template slot="order_type" slot-scope="record,index">
                                <a-tag color="cyan" v-if="record.type == 1">标准版</a-tag>
                                <a-tag color="purple" v-if="record.type == 2">企业版</a-tag>
                                <a-tag color="purple" v-if="record.type == 3">图片版</a-tag>
                            </template>
                            <!-- 交付 -->
                            <template slot="deliver_status" slot-scope="record,index">
                                <a-tag color="red" v-if="record.deliver_status == 0">待交付</a-tag>
                                <a-tag color="red" v-if="record.deliver_status == 2">待修改</a-tag>
                                <a-tag color="purple" v-if="record.deliver_status == 1">待反馈</a-tag>
                                <a-tag color="orange" v-if="record.deliver_status == 4">没资料</a-tag>
                                <a-tag color="blue" v-if="record.deliver_status == 5">部分制作</a-tag>
                                <a-tag color="green" v-if="record.deliver_status == 3">已完成</a-tag>
                            </template>
                            <!-- 支付方式 -->
                            <template slot="pay" slot-scope="record,index">
                                <a-tag color="red" v-if="record.pay_type == 1">淘宝</a-tag>
                                <a-tag color="purple" v-if="record.pay_type == 2">对公</a-tag>
                                <a-tag color="green" v-if="record.pay_type == 3">微信</a-tag>
                                <a-tag color="blue" v-if="record.pay_type == 4">支付宝</a-tag>
                            </template>
                            <!-- 类型 -->
                            <template slot="type" slot-scope="record,index">
                                <a-tag color="cyan" v-if="record.type == 1">标准版</a-tag>
                                <a-tag color="purple" v-if="record.type == 2">企业版</a-tag>
                                <a-tag color="orange" v-if="record.type == 3">图片版</a-tag>
                            </template>
                        </a-table>
                        <a-table rowKey="invoice_id " :columns="correlation_columns" :data-source="record.correlation">
                            <!-- 发票类型 -->
                            <template slot="type" slot-scope="record,index">
                                <a-tag color="red" v-if="record.invoice_type == 1">专票</a-tag>
                                <a-tag color="green" v-if="record.invoice_type == 0">普票</a-tag>
                            </template>
                            <template slot="status" slot-scope="record,index">
                                <a-tag color="red" v-if="record.status == 0">待提交</a-tag>
                                <a-tag color="orange" v-if="record.status == 1">开票中</a-tag>
                                <a-tag color="purple" v-if="record.status == 2">已开票</a-tag>
                                <a-tag color="green" v-if="record.status == 3">已完成</a-tag>
                            </template>
                        </a-table>
                    </template>

                </a-table>
            </div>
        </div>

        <a-modal width="500px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <a-form-model :model="invoiceData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="类型">
                    <a-radio-group v-model="invoiceData.invoice_type" button-style="solid" style="width: 155px;">
                        <a-radio-button :value="0">普票</a-radio-button>
                        <a-radio-button :value="1">专票</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

                <a-form-model-item label="公司名称">
                    <a-input v-model="invoiceData.name" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="开票号">
                    <a-input v-model="invoiceData.code" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="金额">
                    <a-input v-model="invoiceData.amount" style="width: 200px;" type="number" />
                </a-form-model-item>
                <a-form-model-item label="备注">
                    <a-textarea v-model="invoiceData.notes" :rows="4" style="width: 230px;" />
                </a-form-model-item>
                <a-form-model-item label="排序">
                    <a-input v-model="invoiceData.sort" style="width: 200px;" type="number" />
                </a-form-model-item>
                <a-form-model-item label="状态">
                    <a-select v-model="invoiceData.status" style="width: 200px;">
                        <a-select-option :value="0">待开票</a-select-option>
                        <a-select-option :value="1">开票中</a-select-option>
                        <a-select-option :value="2">已开票</a-select-option>
                        <a-select-option :value="3">已完成</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { apiCustomInvoiceList, apiCustomInvoiceSave } from '../../utils/js/api';
import orderList from '../custom/order.vue';
export default {
    components: {
        orderList
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keyword: '',
                designer_id: 0,
                data: ['', '']
            },
            show: false,
            title: '修改',
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '发票id', dataIndex: 'invoice_id', align: 'left' },
                { title: '时间', dataIndex: 'create_time', align: 'left' },
                { title: '公司名称', dataIndex: 'name', align: 'left' },
                { title: '税号', dataIndex: 'code', align: 'left' },
                { title: '金额', dataIndex: 'amount', align: 'left' },
                { title: '类型', key: 'type', align: 'left', scopedSlots: { customRender: 'type' } },
                { title: '状态', key: 'status', align: 'left', scopedSlots: { customRender: 'status' } },
                { title: '备注', dataIndex: 'notes', align: 'left' },
                { title: '操作', key: 'action', align: 'left', scopedSlots: { customRender: 'action' } },
            ],
            order_columns: [
                { title: '订单id', dataIndex: 'order_id', align: 'left' },
                { title: '下单日期', dataIndex: 'consult', align: 'left' },

                { title: '类型', key: 'order_type', align: 'left', scopedSlots: { customRender: 'order_type' } },
                { title: '数量', dataIndex: 'num', align: 'left' },
                { title: '支付方式', key: 'pay', align: 'left', scopedSlots: { customRender: 'pay' } },
                { title: '类型', key: 'type', align: 'left', scopedSlots: { customRender: 'type' } },
                { title: '金额', dataIndex: 'deal', align: 'left' },
                { title: '客户群', dataIndex: 'crowd', align: 'left' },
                { title: '交付状态', key: 'deliver_status', align: 'left', scopedSlots: { customRender: 'deliver_status' } },
                { title: '交付时间', dataIndex: 'deliver_time', align: 'left' }
            ],
            correlation_columns:[
            { title: '发票id', dataIndex: 'invoice_id', align: 'left' },
                { title: '时间', dataIndex: 'create_time', align: 'left' },
                { title: '公司名称', dataIndex: 'name', align: 'left' },
                { title: '税号', dataIndex: 'code', align: 'left' },
                { title: '金额', dataIndex: 'amount', align: 'left' },
                { title: '类型', key: 'type', align: 'left', scopedSlots: { customRender: 'type' } },
                { title: '状态', key: 'status', align: 'left', scopedSlots: { customRender: 'status' } },
                { title: '备注', dataIndex: 'notes', align: 'left' },
            ],
            list: [],
            invoiceData: {}
        }
    },
    created() {
        this.getList();
    },
    methods: {
        editInvoice(detail) {
            console.log('detail', detail)
            this.show = true;
            this.invoiceData = detail;
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiCustomInvoiceList({
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.search.keyword,
                designer_id: this.search.designer_id,
                start_time: this.search.data[0],
                end_time: this.search.data[1]
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        editStatus(detail,status){
            detail.status = status;
            this.invoiceData = detail;
            this.save();
        },
        save() {//提交
            apiCustomInvoiceSave(this.invoiceData).then(res => {
                console.log('res', res)
                this.show = false;
                this.$message.success('修改成功');
                // this.getList();
            })
        },
        changeOperateTime() {
            this.search.data = value;
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        searchAct() {
            this.getList();
        },
        cancelAct() {
            this.search = {
                keyword: '',
                data: ['', ''],
                designer_id: 0
            };
            this.getList();
        }
    }
}
</script>

<style></style>
